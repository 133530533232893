<template>
  <div>
    <sui-grid>
      <sui-grid-column :width="16" :large-screen="2" :widescreen="2" />

      <sui-grid-column :width="16" :large-screen="14" :widescreen="14">
        <sui-form-fields :fields="2">
          <sui-form-field>
            <label>{{ $t('claim.content') }}</label>
            <InputText
              v-model="model.content"
              :disabled="disabled"
              @input="handleInput"
            />
          </sui-form-field>
          <sui-form-field>
            <label>{{ $t('claim.type') }}</label>
            <Dropdown
              v-model="model.claimType"
              :disabled="disabled"
              :options="claimTypeOptions"
              option-label="name"
              option-value="id"
              :filter="true"
              style="width: 100%"
              @input="handleInput"
            />
          </sui-form-field>
        </sui-form-fields>

        <sui-form-fields :fields="2">
          <sui-form-field>
            <label>{{ $t('claim.date_expire') }}</label>
            <DatePicker
              v-model="model.date_expire"
              :disabled="disabled"
              @input="handleInput"
            />
          </sui-form-field>
          <sui-form-field v-if="model.type === 2">
            <sui-checkbox
              v-model="model.dependsOnMinSubsistence"
              :disabled="disabled"
              :label="$t('claim.dependsMinWage')"
              @input="() => handleToggle('dependsOnMinSubsistence')"
            />
          </sui-form-field>

          <sui-form-field>
            <sui-checkbox
              v-model="model.mainClaim"
              :disabled="disabled"
              :label="$t('claim.basic')"
              @input="() => handleToggle('mainClaim')"
            />
          </sui-form-field>
        </sui-form-fields>
      </sui-grid-column>
    </sui-grid>
  </div>
</template>

<script>
import DatePicker from '../../DatePicker.vue';

export default {
  name: 'SubClaim',
  components: {
    DatePicker,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    claimTypeOptions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    model: {
      get() {
        return { ...this.data };
      },
    },
  },
  methods: {
    handleInput() {
      this.$emit('update', this.model);
    },
    handleToggle(name) {
      this.model[name] = !this.model[name];
      this.$emit('update', this.model);
    },
  },
};
</script>
