<template>
  <div>
    <sui-divider />

    <sui-grid>
      <sui-grid-column :width="16" :large-screen="2" :widescreen="2">
        <h3 v-html="$t('person.accounts.title')" />
      </sui-grid-column>

      <sui-grid-column :width="16" :large-screen="14" :widescreen="14">
        <Account
          v-for="(account, index) in accountsList"
          :key="index"
          :banks="banks"
          :data="account"
          :disabled="disabled"
          :show-add="index === accountsList.length - 1"
          :show-delete="accountsList.length > 1"
          @update="(value) => editAccount(value, index)"
          @add="addAccount"
          @delete="() => removeAccount(index)"
        />
      </sui-grid-column>
    </sui-grid>
  </div>
</template>

<script>
// @flow
import { mapGetters } from 'vuex';
import Account from './Account.vue';

export default {
  name: 'PersonAccounts',
  components: {
    Account,
  },
  props: {
    accounts: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      accountsList: [{}],
      newBank: 'HABAEE2X',
      newNumber: '',
      newOwner: '',
    };
  },
  computed: {
    ...mapGetters('banks', ['banks']),
  },
  created() {
    this.$watch(
      'accounts',
      (accounts) => {
        if (accounts.length > 0) {
          this.accountsList = [...accounts];
        }
      },
      { immediate: true }
    );
  },
  methods: {
    addAccount() {
      this.accountsList.push({});
    },
    editAccount(value, index) {
      if (!value.is_main) {
        this.accountsList.map((item, key) => {
          if (key === index) {
            return item;
          }
          // eslint-disable-next-line no-param-reassign
          item.is_main = false;
          return item;
        });
      }
      this.accountsList[index] = value;
      this.$emit('update', this.accountsList);
    },
    removeAccount(index) {
      this.accountsList.splice(index, 1);
      this.$emit('update', this.accountsList);
    },
  },
};
</script>
