<template>
  <sui-form-fields>
    <sui-form-field :width="7">
      <label>{{ $t('person.accounts.owner') }}</label>
      <InputTooltip :text="model.name">
        <Input v-model="model.name" :disabled="disabled" @input="handleInput" />
      </InputTooltip>
    </sui-form-field>

    <sui-form-field :width="5">
      <label>{{ $t('person.accounts.accountNumber') }}</label>
      <InputTooltip :text="model.accountNo">
        <Input
          v-model="model.accountNo"
          :disabled="disabled"
          @input="handleInput"
        />
      </InputTooltip>
    </sui-form-field>
    <sui-form-field :width="2">
      <label>
        {{ $t('person.accounts.is_main') }}
        <i
          v-tooltip="'Valitud kontot kasutatakse csv expordil.'"
          class="pi pi-question-circle"
        ></i>
      </label>
      <sui-checkbox
        v-model="model.is_main"
        :disabled="disabled"
        radio
        @input="handleInput"
      />
    </sui-form-field>

    <sui-form-field v-if="!disabled" :width="2" class="row-actions__field">
      <div class="row-actions__container">
        <DeletePopover v-if="showDelete" use-icon @confirm="handleRemove" />

        <sui-button
          v-if="showAdd"
          class="row-action"
          icon="plus"
          type="button"
          @click.prevent="handleAdd"
        />
      </div>
    </sui-form-field>
  </sui-form-fields>
</template>

<script>
import DeletePopover from '../DeletePopover.vue';
import Input from '../Input.vue';
import InputTooltip from '../InputTooltip.vue';

export default {
  name: 'Account',
  components: {
    DeletePopover,
    Input,
    InputTooltip,
  },
  props: {
    banks: {
      type: Array,
      default: () => {},
    },
    data: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showAdd: {
      type: Boolean,
      default: false,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        let bankFound = null;

        if (this.data.bank) {
          bankFound = this.banks.find((item) => item.id === this.data.bank);
        } else {
          // Linking SWED bank to bank account by default for now..
          bankFound = this.banks.find((item) => item.swift === 'HABAEE2X');
        }

        return {
          ...this.data,
          bank: bankFound ? bankFound.id : null,
        };
      },
    },
  },
  methods: {
    handleInput() {
      this.$emit('update', this.model);
    },
    handleAdd() {
      this.$emit('add');
    },
    handleRemove() {
      this.$emit('delete');
    },
  },
};
</script>
