<template>
  <sui-input
    :disabled="disabled"
    :icon="icon"
    :value="value"
    @input="handleInput"
  />
</template>

<script>
export default {
  name: 'Input',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>
