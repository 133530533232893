/**
 * Enum values for types of bulk actions.
 * NB: Same as in BulkAction::TYPE_* constants! Keep in sync.
 */
export enum BulkActionType {
  SET_START_FEE = 1,
  CLOSE_CASE = 2,
  COMPUTE_NEW_ISSUER_FEE = 3,
  REMOVE_ARREST_PREPARE = 4,
  REMOVE_ARREST_CONFIRM = 5,
  SET_ARREST_PREPARE = 6,
  SET_ARREST_CONFIRM = 7,
  VALIDATE_CASE = 8,
}
