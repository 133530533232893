var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sui-segment',{class:("segment-" + _vm.role)},[(_vm.isMain)?_c('h2',[_vm._v(" "+_vm._s(_vm.$t(("person.role." + _vm.role)))+" ")]):_vm._e(),(_vm.isMain && !_vm.disabled)?_c('Tooltip',{attrs:{"text":_vm.$t('atLeastOneRequired')}}):_vm._e(),_c('sui-grid',[_c('sui-grid-row',[_c('sui-grid-column',{attrs:{"width":16,"large-screen":2,"widescreen":2}},[_c('h3',[_vm._v(_vm._s(_vm.$t('person.info.data')))])]),_c('sui-grid-column',{attrs:{"width":14,"large-screen":7,"widescreen":7}},[_c('PersonInfo',{attrs:{"data":_vm.personData,"disabled":_vm.representativeDisabled || _vm.disabled},on:{"search":_vm.handleSearch,"update":_vm.onUpdatePersonData}})],1),_c('sui-grid-column',{staticClass:"contacts__title",attrs:{"width":16,"large-screen":2,"widescreen":2}},[_c('h3',[_vm._v(_vm._s(_vm.$t('person.contacts.title')))])]),_c('sui-grid-column',{attrs:{"width":16,"large-screen":5,"widescreen":5}},[_c('PersonContacts',{attrs:{"contacts":_vm.personData.contactsData,"disabled":_vm.representativeDisabled || _vm.disabled},on:{"update":_vm.onUpdateContacts}})],1)],1),(_vm.isMain)?_c('PersonAddresses',{attrs:{"addresses":_vm.personData.addressData,"disabled":_vm.disabled},on:{"update":_vm.onUpdateAddresses}}):_vm._e()],1),(_vm.isMain && !(_vm.disabled && _vm.personData.accountsData.length === 0))?_c('PersonAccounts',{attrs:{"accounts":_vm.personData.accountsData,"disabled":_vm.disabled},on:{"update":_vm.onUpdateAccounts}}):_vm._e(),(
      _vm.isMain && !(_vm.disabled && _vm.personData.representativesData.length === 0)
    )?_c('PersonRepresentatives',{attrs:{"disabled":_vm.disabled,"representatives":_vm.personData.representativesData},on:{"update":_vm.onUpdateRepresentatives}}):_vm._e(),(_vm.showButtons)?_c('div',{staticClass:"button__container",staticStyle:{"margin-top":"1rem"}},[_c('sui-divider'),(_vm.disabled)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
        value: _vm.$t('case.fieldsDisabled', {
          state: _vm.$t(_vm.CaseStateToLabel[_vm.caseState]),
        }),
        disabled: _vm.isEditable,
      }),expression:"{\n        value: $t('case.fieldsDisabled', {\n          state: $t(CaseStateToLabel[caseState]),\n        }),\n        disabled: isEditable,\n      }",modifiers:{"top":true}}]},[_c('sui-button',{attrs:{"size":"mini","disabled":!_vm.isEditable},on:{"click":function($event){$event.preventDefault();return _vm.$emit('edit')}}},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")])],1):_vm._e(),(!_vm.disabled)?_c('sui-button',{attrs:{"size":"mini"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]):_vm._e(),(!_vm.disabled)?_c('sui-button',{attrs:{"loading":_vm.isLoading,"primary":"","size":"mini","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }